@import '../../_var'
.footer-container
    position: fixed
    bottom: 0
    width: 100%
    background-color: $grey
    display: flex
    justify-content: flex-end
    align-items: center
    padding: 1rem
    margin-top: 10rem

    @media (max-width: 768px)
        margin-top: 10rem
        width: calc( 100% - 2rem )
        bottom: 0
        position: static

.footer-icons
    font-size: 1.5rem
    margin-right: 5rem
    a
        color: $white
        padding-left: 0.5rem
        transition: all 0.3s ease
        &:hover
            color: $burgundy

.footer-text
    font-size: 1.5rem
    font-weight: 400
    color: $white
    margin-right: 0.3rem
