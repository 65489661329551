@import '../../_var'

.reservation-container
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media (max-width: 768px)
        padding: 0 2rem
    p
        font-size: 2rem
        font-weight: 400
        @media (max-width: 768px)
            font-size: 1.5rem

.reservation-header
    font-size: 3.5rem
    margin-bottom: 20px
    @media (max-width: 768px)
        font-size: 2.5rem

.calendar
    width: 80%
    margin: 0 auto
    border: 10px solid #ccc
    border-radius: 5px
    background-color: #f9f9f9
    font-family: 'Times New Roman', Times, serif
    @media (max-width: 768px)
        width: 100%

    .react-calendar__month-view__days__day
        color: $grey

    .react-calendar__tile--active
        background: $blue-grey
        color: $white

.available-times
    font-family: 'Times New Roman', Times, serif
    font-size: 3rem
    list-style-type: none
    @media (max-width: 768px)
        font-size: 2rem

    li
        display: flex
        justify-content: space-between
        align-items: center

    button
        margin-left: 2rem
        font-weight: 500
        color: $white
        border: 2.5px solid $grey
        background: $grey
        padding: 0.7rem
        transition: all 0.3s ease
        cursor: pointer
        border-radius: 0.65rem
        @media (max-width: 768px)
            padding: 0.5rem
            margin-left: 1rem

        &:hover
            background: $blue-grey
            color: $white
