@import '../../_var'

.material-symbols-outlined
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24

.nav-wrapper
  background: $grey
  padding: 0.5rem 0
  position: sticky
  top: 0
  z-index: 30
  backdrop-filter: blur(20px)

.nav-content
  max-width: 1300px
  display: flex
  align-items: center
  justify-content: space-around
  padding: 1rem 0
  margin: 0 auto
  text-decoration: none

.logo
  width: 12rem
  margin-bottom: 0.7rem
  height: auto
  order: 1

.nav-content ul
  margin-left: -2rem
  order: 2
  display: flex
  list-style: none
  gap: 6rem
  align-self: center

.nav-content li
  display: flex
  align-items: center
  margin: 0 1.5rem

.menu-item
  text-decoration: none
  font-size: 1.2rem
  font-weight: 500
  color: #fff
  position: relative
  cursor: pointer
  position: relative
  overflow: hidden
  &:hover
    opacity: 1
    color: $light-pink

  &::before
    transition: 300ms
    top: 22px
    height: 5px
    content: ""
    position: absolute
    background-color: $burgundy
    width: 0%
    bottom: 10px

  &:hover::before
    width: 100%

.menu-btn
  width: 2.5rem
  height: 2.5rem
  font-size: 1.5rem
  display: none
  align-items: center
  justify-content: center
  border: none
  border-radius: 0.4rem
  color: $grey
  background: $burgundy
  cursor: pointer
  transition: all 0.4s ease
  line-height: 0
  transition: transform 0.3s ease-in-out
  &:hover
    color: $grey
    background: $burgundy
    border: 1px solid $light-pink

  &.open
    animation: forwards

  &:active, &:focus
    color: #fff
    background: $burgundy
    border: none

  @media (max-width: 768px)
    width: 2.5rem
    height: 2.5rem
    margin-left: 1rem

.disabled
  pointer-events: none
  color: $light-pink

@media (max-width: 425px)
  .logo
    width: 10rem
    height: auto

@media (max-width: 769px)
  .menu-btn
    display: block

  .contact-btn
    display: none

  .nav-content ul
    display: none

@media (max-width: 1325px)
  .nav-wrapper
    padding: 0 2rem

@media (max-width: 769px)
  .nav-content
    justify-content: space-between
    max-width: 100vw

  .logo
    order: 1
    width: 11rem

  .menu-btn
    order: 2
    display: block

  .contact-btn
    display: none

  .nav-content ul
    display: none

@media (max-width: 769px)
  .nav-wrapper
    padding: 0 2rem
