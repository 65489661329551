.aboutme-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 1rem

  @media (max-width: 768px)
    margin-left: 2.5rem // Dodaj media query dla małych ekranów
    padding: 1rem

.img-container
  display: flex
  flex-direction: row
  align-items: baseline
  gap: 2rem

  @media (max-width: 768px)  // Dodaj media query dla małych ekranów
    flex-direction: column
    align-items: center

  img
    border-radius: 10%

.sing1, .sing2
  width: 600px
  height: 400px
  object-fit: cover

  @media (max-width: 768px)  // Dodaj media query dla małych ekranów
    width: 100%
    height: auto

.sing2
  margin-top: 2rem

.aboutme-content
  flex: 1
  margin-top: 1rem
  font-size: 2rem
  font-weight: 400

  @media (max-width: 768px)  // Dodaj media query dla małych ekranów
    font-size: 1.5rem

h1
  font-size: 3.5rem

  @media (max-width: 768px)  // Dodaj media query dla małych ekranów
    font-size: 2.5rem
