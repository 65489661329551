@import '../../_var'
.contact-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 1rem

  @media (max-width: 768px)
    padding: 1rem

.contact-heading
  font-size: 3rem
  font-weight: 700
  margin-top: 2.2rem

  @media (max-width: 768px)
    font-size: 2rem

.contact-text
  flex: 1
  margin-top: 2rem
  font-size: 2rem
  font-weight: 400

  @media (max-width: 768px)
    font-size: 1.5rem

.emailForm
  margin-top: 5rem
  width: 100%

form
  display: flex
  flex-direction: column
  grid-gap: 1.5rem
  align-items: center

.name-container
  display: flex
  align-items: center
  grid-gap: 2rem

  @media (max-width: 768px)
    flex-direction: column

form input, form textarea
    flex: 1
    width: 50%
    font-size: 1rem
    color: $grey
    background: $white
    border-radius: 0.6rem
    border: 1.5px solid $light-pink
    padding: 1rem

form button
    font-size: 1rem
    font-weight: 500
    color: $white
    border: 2.5px solid $grey
    background: $grey
    padding: 0.7rem
    transition: all 0.3s ease
    cursor: pointer
    border-radius: 0.65rem
    width: 50%

form button:hover
    color: $white
    background: $blue-grey

.submit-success
  margin-top: 1.5rem
  font-size: 2rem
  color: $blue-grey
  font-weight: 600

.error
  font-size: 1.2rem
  font-weight: 600
  color: $burgundy

.error-field
  border: 1px solid red
