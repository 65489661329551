@import '../../../_var'

.mobile-menu
  width: 50vw
  height: 100vh
  display: none
  background-color: $grey
  position: fixed !important
  left: 0
  top: 0
  z-index: 999 !important
  opacity: 0
  box-shadow: 0px 29px 68px rgba(0, 0, 0, 0.3)
  transition: all 0.3s ease
  transform: translateX(-100vw)
  overflow-y: auto
  color: $grey

  &-container
    color: $grey
    width: 30vw
    height: 100vh
    padding: 2rem
    display: flex
    flex-direction: column
    justify-content: center
    position: fixed
    z-index: 999 !important

  &.active
    opacity: 1
    position: fixed !important
    overflow: auto
    transform: translateX(0)
    transition-delay: 0.2s
    height: 100%
    color: $grey

  & img
    margin-bottom: 3rem
    animation: slideInUp 0.7s ease

  & ul
    color: $grey
    list-style: none
    display: flex
    flex-direction: column
    gap: 1rem
    animation: fadeIn 0.5s ease
    margin-left: -1rem
    font-size: 4rem

@keyframes slideInUp
  0%
    transform: translateY(100%)
    opacity: 0

  100%
    transform: translateY(0)
    opacity: 1

@keyframes fadeIn
  0%
    opacity: 0

  100%
    opacity: 1

@media (max-width: 768px)
  .mobile-menu
    display: block

.button-class-name:active,
.button-class-name:focus
  background-color: var(--light-pink)
