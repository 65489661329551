@import './_var'
::-webkit-scrollbar
  display: none
body
  padding-top: constant(safe-area-inset-top)
  padding-bottom: constant(safe-area-inset-bottom)
  padding-left: constant(safe-area-inset-left)
  padding-right: constant(safe-area-inset-right)
  padding-top: env(safe-area-inset-top)
  padding-bottom: env(safe-area-inset-bottom)
  padding-left: env(safe-area-inset-left)
  padding-right: env(safe-area-inset-right)

body
  background-color: $light-pink
  margin-bottom: 4rem
  @media (max-width: 768px)
    max-width: 100% !important
    margin-bottom: 0rem

.container
  max-width: 1400px !important
  margin: 0 auto

  @media (max-width: 1300px)
    padding: 0 1rem

  @media (max-width: 768px)
    max-width: 100% !important
    padding: 0 0.5rem

.component-spacing
  margin-bottom: 2rem

.fade-enter
  opacity: 0

  &-active
    opacity: 1
    animation: fadeInOut 1.8s ease-in-out

.fade-exit
  opacity: 1

  &-active
    opacity: 0
    animation: fadeInOut 1.8s ease-in-out
