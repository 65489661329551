@import '../../_var'
.hero-container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 6rem 0
  margin-top: 1rem
  color: $grey

  @media (max-width: 768px)
    flex-direction: column
    margin-left: 2rem
    padding: 7rem 0

.hero-content
  flex: 1
  margin-left: 2rem

  @media (max-width: 768px)
    margin-left: 0
    text-align: center

.hero-img
  width: 55%
  height: 55%
  border-radius: 10%

  @media (max-width: 768px)
    width: 100%
    height: auto

.hero-content h1
  font-size: 4rem
  font-weight: 700
  line-height: 0.1

  @media (max-width: 768px)
    font-size: 2rem

.hero-content h2
  font-size: 3rem
  font-weight: 600

  @media (max-width: 768px)
    font-size: 1.5rem

.hero-content p
  font-size: 1.5rem
  font-weight: 600

  @media (max-width: 768px)
    font-size: 1rem

// ... (reszta kodu)

.fade-enter
  opacity: 0.01
  transform: translateY(80px)

.fade-enter-active
  opacity: 1
  transform: translateY(0)
  transition: opacity 500ms ease-in, transform 500ms ease-in

.fade-exit
  opacity: 1

.fade-exit-active
  opacity: 0.01
  transition: opacity 300ms ease-in
